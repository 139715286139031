import path from "path";

import { Storage } from "aws-amplify";
import Resizer from "react-image-file-resizer";
import moment from "moment";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";

import {
  Case,
  CaseForExternalShare,
  PackPhase,
  PhotoForExternalShare,
  Product,
  ProductForExternalShare,
  Project,
  ProjectForExternalShare,
  Schedule,
  ScheduleForExternalShare,
} from "API";

export const MAX_SIZE = 1 * 1024 * 1024; // PAX-609 アップロードされた画像の閾値

// PaxImage はPax上で扱う画像の型です。
// 基本的にS3の画像とindex(全体の中で何番目か、0オリジン)とtotal(全体の数)を持ちます。
// indexとtotalは外部共有時のみ使います。
export type PaxImage = S3ProviderListOutputItem & {
  index?: string;
  total?: string;
};

/**
 * PhotoForExternalShare を PaxImage に変換する関数
 * @param obj 変換元
 * @returns 変換後
 */
export function convertToPaxImage(obj: PhotoForExternalShare): PaxImage {
  return {
    key: obj.key,
    eTag: obj.eTag ?? "",
    size: obj.size ?? 0,
    lastModified: obj.lastModified ? new Date(obj.lastModified) : undefined,
    total: String(obj.total) ?? "-1",
    index: String(obj.index) ?? "-1",
  };
}

export function isDownloadedS3ImageObject(
  obj: unknown
): obj is { ETag: string; Body: Blob } {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "ETag" in obj &&
    "Body" in obj &&
    typeof (obj as { ETag: unknown }).ETag === "string" &&
    (obj as { Body: unknown }).Body instanceof Blob
  );
}

// PAX-609 アップロードされた画像が1mbを超える場合、リサイズするための関数
export const resizeImage = async (file: File): Promise<File> => {
  return new Promise<File>((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1920,
      1080,
      "JPEG",
      100,
      0,
      (uri) => {
        if (uri instanceof Blob) {
          resolve(new File([uri], file.name, { type: "image/jpeg" }));
        } else {
          reject(new Error("Resizing failed"));
        }
      },
      "blob",
      200,
      200
    );
  });
};

/**
 * エンコードされたファイル名を生成する関数
 * @param {string} fileName - エンコードするファイル名
 * @returns {string} エンコードされたファイル名
 * @description
 * 日本語のファイル名をURLエンコードし、特殊文字をアンダーバーに置き換えた後、
 * 拡張子を追加して返します。
 */
export const encodeFileName = (fileName: string): string => {
  const ext = path.extname(fileName);
  const excludeExt = fileName.replace(ext, "");
  // 日本語のファイル名をエンコードし、更に後のサイズをつける処理のため特殊文字をアンダーバーに変更
  return (
    encodeURIComponent(excludeExt).replace(/[^a-zA-Z0-9]/g, "_") +
    (ext ? `${ext}` : "")
  );
};

export type ImageSize = "xs" | "sm" | "md" | "lg";

export const isImage = (file: S3ProviderListOutputItem) => {
  if (!file || !file.key) return false;
  const ext = file.key.split(".")[1];
  if (!ext) return false;
  if (
    [
      "jpeg",
      "jpg",
      "png",
      "jpe",
      "jif",
      "gif",
      "webp",
      "tiff",
      "tif",
      "bmp",
      "svg",
      "ai",
    ].includes(ext.toLowerCase())
  ) {
    return true;
  }
  return false;
};

export const getImageName = (img: PaxImage) => {
  if (!img.key) return "";
  return getImageNameFromKey(img.key);
};

export const getImageNameFromKey = (key: string | null) => {
  if (!key) return null;
  const fileName = key.split("/").at(-1);
  if (!fileName) return key;
  return fileName
    .replace("lg_", "")
    .replace("md_", "")
    .replace("sm_", "")
    .replace("xs_", "");
};

export const getImageKeyBySize = (key: string, size?: ImageSize) => {
  if (!size) return key;
  let newKey = key
    .replace("/xs_", `/${size}_`)
    .replace("/sm_", `/${size}_`)
    .replace("/md_", `/${size}_`);
  if (!newKey.includes(`/${size}_`)) {
    const imgName = newKey.split("/").at(-1);
    if (!imgName) return newKey;
    newKey = newKey.replace(imgName, `${size}_${imgName}`);
  }
  return newKey;
};

export const deleteImages = async (imageKeys: string[]) => {
  let delProm: Promise<any>[] = [];
  imageKeys.forEach((k: string) => {
    const lgFileKey = getImageKeyBySize(k, "lg");
    delProm = delProm.concat([
      Storage.remove(k),
      Storage.remove(lgFileKey),
      Storage.remove(lgFileKey.replace("/lg_", "/xs_")),
      Storage.remove(lgFileKey.replace("/lg_", "/sm_")),
      Storage.remove(lgFileKey.replace("/lg_", "/md_")),
    ]);
  });
  await Promise.all(delProm);
};

/**
 * Windowsでフォルダ名に使用できない文字をサニタイズする関数
 * @param folderName サニタイズするフォルダ名
 * @returns サニタイズされたフォルダ名
 */
export const sanitizeFolderName = (folderName: string) => {
  const invalidChars = /[<>:"/\\|?*\.]/g;
  return folderName.replace(invalidChars, "_");
};

/**
 * generateZipFolderName はzipファイルのフォルダ名を生成する関数です。
 * @param project プロジェクト情報
 * @returns 生成されたzipフォルダ名
 */
export const generateZipFolderName = (
  project: Project | ProjectForExternalShare | undefined
): string => {
  if (!project) return "";
  return `${project.name}_${project.account?.name}御中_${moment().format(
    "YYYYMMDDHHmmss"
  )}`;
};

export type ImageTypeAll =
  | ImageTypeStockTop
  | ImageTypeStockSub
  | ImageTypeShipSub
  | ImageTypeProductCase
  | ImageTypeProjectPhase;
export type ImageTypeStockTop = "stock";
export type ImageTypeStockSub = "stockSchedule" | "stockScheduleFolder";
export type ImageTypeShipSub = "shipSchedule" | "shipScheduleFolder";
export type ImageTypeProductCase = "productCase";
export type ImageTypeProjectPhase = "projectPhase";

/**
 * generateImageName は Pax で Image を表示・ダウンロードする際の名前を生成します。
 * @param currentImageType ImageTypeAll から適切なものを選択します
 * @param index 1から始まる通し番号
 * @param key 画像のキー(s3でのpath)
 * @param products ImageType によっては利用する product 情報
 * @param project ImageType によっては利用する project 情報
 * @param schedules ImageType によっては利用する schedule 情報
 * @param delimiter 各要素の区切り文字
 * @param removeExt 拡張子を削除するかどうか
 * @returns 生成された画像ラベル
 */
export function generateImageName(
  imageType: ImageTypeAll,
  index: number,
  key: string,
  products: Product[] | ProductForExternalShare[],
  project: Project | ProjectForExternalShare | undefined,
  schedules: Schedule[] | ScheduleForExternalShare[],
  delimiter: string = "_",
  removeExt: boolean = false
): string {
  const ext = removeExt ? "" : path.extname(key);

  switch (imageType) {
    case "stock":
      return stockImageName(index, delimiter, ext);
    case "stockSchedule":
      return stockScheduleImageName(index, key, schedules, delimiter, ext);
    case "stockScheduleFolder":
      return stockScheduleFolderImageName(
        index,
        key,
        schedules,
        delimiter,
        ext
      );
    case "shipSchedule":
      return shipScheduleImageName(index, key, schedules, delimiter, ext);
    case "shipScheduleFolder":
      return shipScheduleFolderImageName(index, key, schedules, delimiter, ext);
    case "productCase":
      return productCaseImageName(index, key, products, delimiter, ext);
    case "projectPhase":
      if (project) {
        return projectPhaseImageName(index, key, project, delimiter, ext);
      } else {
        throw new Error("Project data is required for projectPhase type");
      }
    default:
      throw new Error("Unknown image type");
  }
}

/**
 * generateImageNameByKey は key のパスに従って適切な generateImageName 関数を呼び出し、画像名を生成します。
 * @param index 1から始まる通し番号
 * @param key 画像のキー(s3でのpath)
 * @param products ImageTypeによっては利用するproduct情報
 * @param project ImageTypeによっては利用するproject情報
 * @param schedules ImageTypeによっては利用するschedule情報
 * @param delimiter 各要素の区切り文字
 * @param removeExt 拡張子を削除するかどうか
 * @returns 生成された画像ラベル
 */
export function generateImageNameByKey(
  index: number,
  key: string,
  products: Product[] | ProductForExternalShare[],
  project: Project | ProjectForExternalShare | undefined,
  schedules: Schedule[] | ScheduleForExternalShare[],
  delimiter: string = "_",
  removeExt: boolean = false
): string {
  // keyからtypeを特定
  const imageType = determineImageType(key);

  // 特定されたtypeに基づいてgenerateImageNameを呼び出し
  return generateImageName(
    imageType,
    index,
    key,
    products,
    project,
    schedules,
    delimiter,
    removeExt
  );
}

/**
 * stockImageName は入荷時の画像名を生成します。
 * @param index 通し番号
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function stockImageName(index: number, delimiter: string, ext: string): string {
  const indexStr = String(index).padStart(2, "0");
  return `入荷${delimiter}${indexStr}${ext}`;
}

/**
 * stockScheduleImageName は入荷スケジュール時の画像名を生成します。
 * @param index 通し番号
 * @param key 画像のキー(s3でのpath)
 * @param schedules スケジュール情報
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function stockScheduleImageName(
  index: number,
  key: string,
  schedules: Schedule[] | ScheduleForExternalShare[],
  delimiter: string,
  ext: string
): string {
  const indexStr = String(index).padStart(2, "0");
  if (key.split("/").length < 4) {
    return `入荷${delimiter}${indexStr}${ext}`;
  }
  const scheduleId = key.split("/")[3];
  const schedule = (
    schedules as Array<Schedule | ScheduleForExternalShare>
  ).find((s) => s.id === scheduleId);
  return `入荷${delimiter}${schedule?.name ?? ""}${delimiter}${indexStr}${ext}`;
}

/**
 * stockScheduleFolderImageName は入荷スケジュールフォルダ時の画像名を生成します。
 * @param index 通し番号
 * @param key 画像のキー(s3でのpath)
 * @param schedules スケジュール情報
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function stockScheduleFolderImageName(
  index: number,
  key: string,
  schedules: Schedule[] | ScheduleForExternalShare[],
  delimiter: string,
  ext: string
): string {
  const indexStr = String(index).padStart(2, "0");
  if (key.split("/").length < 5) {
    return `入荷${delimiter}${indexStr}${ext}`;
  }
  const scheduleId = key.split("/")[3];
  const folderId = key.split("/")[4];
  const schedule = (
    schedules as Array<Schedule | ScheduleForExternalShare>
  ).find((s) => s.id === scheduleId);
  const folder = schedule?.stockSubFolders?.find((f) => f && f.id === folderId);
  return `入荷${delimiter}${schedule?.name ?? ""}${delimiter}${
    folder?.name ?? ""
  }${delimiter}${indexStr}${ext}`;
}

/**
 * shipScheduleImageName は出荷スケジュール時の画像名を生成します。
 * @param index 通し番号
 * @param key 画像のキー(s3でのpath)
 * @param schedules スケジュール情報
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function shipScheduleImageName(
  index: number,
  key: string,
  schedules: Schedule[] | ScheduleForExternalShare[],
  delimiter: string,
  ext: string
): string {
  const indexStr = String(index).padStart(2, "0");
  if (key.split("/").length < 4) {
    return `出荷${delimiter}${indexStr}${ext}`;
  }
  const scheduleId = key.split("/")[3];
  const schedule = (
    schedules as Array<Schedule | ScheduleForExternalShare>
  ).find((s) => s.id === scheduleId);
  return `出荷${delimiter}${schedule?.name ?? ""}${delimiter}${indexStr}${ext}`;
}

/**
 * shipScheduleFolderImageName は出荷スケジュールフォルダ時の画像名を生成します。
 * @param index 通し番号
 * @param key 画像のキー(s3でのpath)
 * @param schedules スケジュール情報
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function shipScheduleFolderImageName(
  index: number,
  key: string,
  schedules: Schedule[] | ScheduleForExternalShare[],
  delimiter: string,
  ext: string
): string {
  const indexStr = String(index).padStart(2, "0");
  if (key.split("/").length < 5) {
    return `出荷${delimiter}${indexStr}${ext}`;
  }
  const scheduleId = key.split("/")[3];
  const folderId = key.split("/")[4];
  const schedule = (
    schedules as Array<Schedule | ScheduleForExternalShare>
  ).find((s) => s.id === scheduleId);
  const folder = schedule?.shipSubFolders?.find((f) => f && f.id === folderId);
  return `出荷${delimiter}${schedule?.name ?? ""}${delimiter}${
    folder?.name ?? ""
  }${delimiter}${indexStr}${ext}`;
}

/**
 * productCaseImageName は梱包ケースの画像名を生成します。
 * @param index 通し番号
 * @param key 画像のキー(s3でのpath)
 * @param products プロダクト情報
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function productCaseImageName(
  index: number,
  key: string,
  products: Product[] | ProductForExternalShare[],
  delimiter: string,
  ext: string
): string {
  const indexStr = String(index).padStart(2, "0");
  if (key.split("/").length < 4) {
    return `梱包${delimiter}${indexStr}${ext}`;
  }
  const productId = key.split("/")[3];
  const caseId = key.split("/")[4];
  const phaseId = key.split("/")[5];
  const product = (products as Array<Product | ProductForExternalShare>).find(
    (p) => p.id === productId
  );
  const productCase = (
    product?.cases as Array<Case | CaseForExternalShare | null>
  )?.find((c) => c?.order && `${c?.order}` === caseId);
  const phase = productCase?.packPhases?.find(
    (p: PackPhase | null) => p && p.id === phaseId
  );
  const phaseIdx = productCase?.packPhases?.findIndex(
    (p: PackPhase | null) => p && p.id === phaseId
  );
  return `梱包${delimiter}${productCase?.name ?? ""}${delimiter}${String(
    (phaseIdx ?? 0) + 1
  ).padStart(2, "0")}${phase?.name ?? ""}${delimiter}${indexStr}${ext}`;
}

/**
 * projectPhaseImageName は案件フェーズの画像名を生成します。
 * @param index 通し番号
 * @param key 画像のキー(s3でのpath)
 * @param project プロジェクト情報
 * @param delimiter 区切り文字
 * @param ext ファイル拡張子
 * @returns 生成された画像名
 */
function projectPhaseImageName(
  index: number,
  key: string,
  project: Project | ProjectForExternalShare,
  delimiter: string,
  ext: string
): string {
  const indexStr = String(index).padStart(2, "0");
  if (key.split("/").length < 3) {
    return `案件${delimiter}${indexStr}${ext}`;
  }
  const phaseId = key.split("/")[2];
  const phase = project.photoPhases?.find((p) => p && p.id === phaseId);
  const phaseIdx = project.photoPhases?.findIndex((p) => p && p.id === phaseId);
  return `案件${delimiter}${String((phaseIdx ?? 0) + 1).padStart(2, "0")}${
    phase?.name ?? ""
  }${delimiter}${indexStr}${ext}`;
}

/**
 * determineImageType は key のパスに基づいて ImageType を特定します。
 * @param key 画像のキー(s3でのpath)
 * @returns 特定された ImageTypeAll
 */
function determineImageType(key: string): ImageTypeAll {
  // ImageType毎にkeyは以下のルールで名前がつく
  // - stock: <project_id>/photo/stocking/<size>_<filename>
  // - stockSchedule or shipSchedule: <project_id>/photo/<stocking or shipping>/<schedule_id>/<size>_<filename>
  // - stockSheduleFolder or shipScheduleFolder: <project_id>/photo/<stocking or shipping>/<schedule_id>/<sub_folder_id>/output_1.png
  // - projectPhase: <project_id>/photo/<photo_phase_id>/<size>_<filename>
  // - productCase: <project_id>/photo/products/<product_id>/<case_id>/<pack_phase_id>/<size>_<filename>
  // /で区切った3個目とそもそもの要素数で判断する
  const splitKey = key.split("/");
  if (splitKey.length < 2) {
    throw new Error("Invalid key format");
  }
  const subType = splitKey[2];

  if (subType === "stocking") {
    if (splitKey.length === 4) {
      return "stock";
    } else if (splitKey.length === 5) {
      return "stockSchedule";
    } else if (splitKey.length === 6) {
      return "stockScheduleFolder";
    }
  } else if (subType === "shipping") {
    if (splitKey.length === 5) {
      return "shipSchedule";
    } else if (splitKey.length === 6) {
      return "shipScheduleFolder";
    }
  } else if (subType === "products") {
    return "productCase";
  } else {
    return "projectPhase";
  }

  throw new Error("Unknown type");
}

/**
 * S3ProviderListOutputItemを作成日順でソートする関数
 * keyに作成日がULIDで含まれているのでそれでソートする
 * 含まれていなかったらlastModifiedでソートする
 * @param a ソート対象
 * @param b ソート対象
 * @returns aがbより前の場合は-1、それ以外は1
 */
export function sortByCreatedDate(
  a: S3ProviderListOutputItem,
  b: S3ProviderListOutputItem
) {
  // まずはファイル名取得
  const aKey = a.key?.split("/").at(-1);
  const bKey = b.key?.split("/").at(-1);
  if (!aKey || !bKey) {
    return moment(a.lastModified).isBefore(b.lastModified) ? -1 : 1;
  }

  // _がなかったらlastModifiedで比較
  if (!aKey.includes("_") || !bKey.includes("_")) {
    return moment(a.lastModified).isBefore(b.lastModified) ? -1 : 1;
  }

  // keyから拡張子を除く
  const aExt = path.extname(aKey);
  const bExt = path.extname(bKey);
  const aName = path.basename(aKey, aExt);
  const bName = path.basename(bKey, bExt);

  // _で分割した最後の要素がULID
  const aULID = aName.split("_").at(-1);
  const bULID = bName.split("_").at(-1);
  if (!aULID || !bULID) {
    return moment(a.lastModified).isBefore(b.lastModified) ? -1 : 1;
  }

  return aULID < bULID ? -1 : 1;
}

const SIZE_PREFIXES = ["xs_", "sm_", "md_", "lg_"];

/**
 * removeSizePrefix は写真の key から画像サイズを削除する関数です。
 * @param key 画像の key
 * @returns 画像サイズが削除された key
 */
export function removeSizePrefix(key: string): string {
  // ファイル名の先頭にsize prefixがついているのでそれを削除
  const baseName = path.basename(key);
  const dirName = path.dirname(key);
  // size prefixがついている場合はそれを削除
  let sizePrefixRemovedBaseName = baseName;
  for (const prefix of SIZE_PREFIXES) {
    if (baseName.startsWith(prefix)) {
      sizePrefixRemovedBaseName = baseName.replace(prefix, "");
    }
  }
  // dirNameに連結する
  return path.join(dirName, sizePrefixRemovedBaseName);
}

/**
 * ImageのKeyを対象のみに絞り込む関数
 * @param key 確認する対象のimageのkey
 * @param size 対象サイズ
 * @param targetPath 対象path
 * @returns 対象かどうか
 */
export function filterImageKey(
  key: string,
  size: string,
  targetPath: string
): boolean {
  // 画像のkeyの種類は以下の通り
  // - stock: <project_id>/photo/stocking/<size>_<filename>
  // - stockSchedule or shipSchedule: <project_id>/photo/<stocking or shipping>/<schedule_id>/<size>_<filename>
  // - stockSheduleFolder or shipScheduleFolder: <project_id>/photo/<stocking or shipping>/<schedule_id>/<sub_folder_id>/output_1.png
  // - projectPhase: <project_id>/photo/<photo_phase_id>/<size>_<filename>
  // - productCase: <project_id>/photo/products/<product_id>/<case_id>/<pack_phase_id>/<size>_<filename>
  return (
    // 指定したサイズの画像のみ取得
    key.includes(`/${size}_`) &&
    // 入出荷写真は親フォルダをpathで指定したときに子フォルダの画像まで取得したくないため、pathで指定した階層直下の写真のみ取得
    // stockScheduleでpathが<project_id>/photo/<stocking or shipping>/<schedule_id>の場合、stockSheduleFolderの画像も取得してしまうのを防ぐため
    ((!key.includes(`/photo/shipping`) && !key?.includes(`/photo/stocking`)) ||
      !key.replace(`${targetPath}/`, "").includes("/")) &&
    // 部分一致によりケース1を取得するとケース10や11など取得できてしまう
    key.includes(`${targetPath}\/`)
  );
}

/**
 * 受け取ったkeyがproductCaseのkeyで引数のproductのものかどうかを判定する関数
 * @param key 確認するkey
 * @param productId 確認するproductのid
 * @returns productのkeyかどうか
 */
export function isProductCaseKey(key: string, productId: string): boolean {
  const imageType = determineImageType(key);
  if (imageType !== "productCase") return false;
  // - productCase: <project_id>/photo/products/<product_id>/<case_id>/<pack_phase_id>/<size>_<filename>
  const splitKey = key.split("/");
  if (splitKey.length < 6) return false;
  const productIdInKey = splitKey[3];
  return productIdInKey === productId;
}
