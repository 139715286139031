import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useRouter } from "next/router";

import { useAlerts } from "contexts/alerts";
import {
  ProductForExternalShare,
  ProjectForExternalShare,
  ScheduleForExternalShare,
  SignInExternalSharePageOutput,
  VerifyExternalShareAccessTokenOutput,
} from "API";
import { convertToPaxImage, PaxImage } from "utils/image";

interface ExternalShareInfoContextValue {
  isExternalLogin: boolean;
  loading: boolean;
  login: (projectId: string, password: string) => Promise<void>;
  logout: (projectId: string) => void;
  project: ProjectForExternalShare | null;
  schedules: ScheduleForExternalShare[];
  products: ProductForExternalShare[];
  photos: PaxImage[];
}

const ExternalShareInfoContext = createContext<ExternalShareInfoContextValue>({
  isExternalLogin: false,
  loading: false,
  login: () => Promise.resolve(),
  logout: () => {},
  project: null,
  schedules: [] as ScheduleForExternalShare[],
  products: [] as ProductForExternalShare[],
  photos: [] as PaxImage[],
});

export const ExternalShareInfoProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [isExternalLogin, setIsExternalLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<ProjectForExternalShare | null>(null);
  const [schedules, setSchedules] = useState<ScheduleForExternalShare[]>([]);
  const [products, setProducts] = useState<ProductForExternalShare[]>([]);
  const [photos, setPhotos] = useState<PaxImage[]>([]);
  const host = typeof window !== "undefined" ? window.location.hostname : "";
  const router = useRouter();
  const { projectId } = router.query;
  const { addAlert } = useAlerts();
  const errAuthFailed = "認証に失敗しました";
  const errPasswordWrong = "パスワードを間違えています";
  const errTokenExpired = "再ログインしてください";

  useEffect(() => {
    if (projectId) loginByAccessToken(projectId as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginByAccessToken = async (projectId: string) => {
    setLoading(true);
    // Storageにアクセスするために匿名ユーザの認証情報作成
    try {
      await Auth.currentCredentials();
    } catch (err) {
      addAlert({
        message: errAuthFailed,
        severity: "error",
      });
      setLoading(false);
      return;
    }

    // API経由で認証を呼ぶ
    try {
      const result = await axios.post("/api/externalShare/verifyToken", {
        projectId,
      });
      if (result.status !== 200) {
        setIsExternalLogin(false);
        setLoading(false);
        return;
      }
      const data: VerifyExternalShareAccessTokenOutput = result.data;
      // ログイン失敗
      if (!data.result) {
        switch (data.errReason) {
          case "expiredToken":
            addAlert({
              message: errTokenExpired,
              severity: "error",
            });
            break;
        }
        setIsExternalLogin(false);
        setLoading(false);
        return;
      }

      // ログイン成功
      const sortedProducts =
        data.products?.sort((a, b) => a.order - b.order) ?? [];
      if (data.products) setProducts(sortedProducts);
      if (data.project) setProject(data.project);
      if (data.schedules) setSchedules(data.schedules);
      if (data.photos) setPhotos(data.photos.map((p) => convertToPaxImage(p)));
      setIsExternalLogin(true);
    } catch (err) {
      setIsExternalLogin(false);
    } finally {
      setLoading(false);
    }
  };

  const login = async (projectId: string, password: string) => {
    setLoading(true);

    // Storageにアクセスするために匿名ユーザの認証情報作成
    try {
      await Auth.currentCredentials();
    } catch (err) {
      addAlert({
        message: errAuthFailed,
        severity: "error",
      });
      setLoading(false);
      return;
    }

    // API経由で認証を呼ぶ
    try {
      const result = await axios.post("/api/externalShare/signIn", {
        projectId,
        password,
        host,
      });
      if (result.status !== 200) {
        addAlert({
          message: errAuthFailed,
          severity: "error",
        });
        setIsExternalLogin(false);
        setLoading(false);
        return;
      }
      const data: SignInExternalSharePageOutput = result.data;
      // ログイン失敗
      if (!data.result) {
        switch (data.errReason) {
          case "invalidRequest":
            addAlert({
              message: errAuthFailed,
              severity: "error",
            });
            break;
          case "invalidPassword":
            addAlert({
              message: errPasswordWrong,
              severity: "error",
            });
            break;
          default:
            addAlert({
              message: errAuthFailed,
              severity: "error",
            });
        }
        setIsExternalLogin(false);
        setLoading(false);
        return;
      }

      // ログイン成功
      const sortedProducts =
        data.products?.sort((a, b) => a.order - b.order) ?? [];
      if (data.products) setProducts(data.products);
      if (data.project) setProject(data.project);
      if (data.schedules) setSchedules(data.schedules);
      if (data.photos) setPhotos(data.photos.map((p) => convertToPaxImage(p)));
      setIsExternalLogin(true);
    } catch (err) {
      addAlert({
        message: errAuthFailed,
        severity: "error",
      });
      setIsExternalLogin(false);
    } finally {
      setLoading(false);
    }
  };

  const logout = async (projectId: string) => {
    if (projectId === "") return;
    setIsExternalLogin(false);
    setProducts([]);
    setProject(null);
    setSchedules([]);
    setPhotos([]);
    const result = await axios.post("/api/externalShare/signOut", {
      projectId,
    });
    if (result.status !== 200) {
      addAlert({
        message: "ログアウトに失敗しました",
        severity: "error",
      });
      return;
    }
    addAlert({
      message: "ログアウトしました",
      severity: "success",
    });
  };

  // useMemo to prevent force re-render components that are reading these values
  const values = useMemo(
    () => ({
      isExternalLogin,
      loading,
      login,
      logout,
      products,
      project,
      schedules,
      photos,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExternalLogin, loading, host]
  );

  return (
    <ExternalShareInfoContext.Provider value={values}>
      {children}
    </ExternalShareInfoContext.Provider>
  );
};

export const useExternalShareInfo = () => {
  const externalShareInfoContext = useContext(ExternalShareInfoContext);

  if (externalShareInfoContext === undefined) {
    throw new Error(
      "useExternalShareInfo must be within ExternalShareInfoProvider"
    );
  }

  return externalShareInfoContext;
};
